<welcome>
    <h2 class="english">
        <a href="/lib/english-sessions" target="_blank">English speakers friendly! (click me)</a>
    </h2>
    <article>
        <div class="welcome-top">
            <h1>ברוכים הבאים ללו-ג'ונג - מרכז לאימון תודעה, איזון והתפתחות אישית בגישה בודהיסטית, יוגית וטנטרית</h1>
            <h3 class="sub-header">
                שלווה, בהירות ואיזון בעולם מודרני, בתרבות מאתגרת ומורכבת
                <br>
            </h3>
            <p class="slogan2">
                בלו-ג'ונג, אנו מביאים חכמה עתיקה אל תוך העולם המודרני: שילוב של טכניקות ושיטות מהמזרח וטכניקות ניהול
                מודרניות, כדי ליצור איזון, שלווה והגשמה אישית ומקצועית.
                <br>
                אנו עוסקים בפיתוח אישי המדגיש פיתוח בהירות פנימית קורנת וחדה, ומיומנויות לחיים - פנימיות וחיצוניות - של
                תודעה מאומנת, מאוזנת ורגועה
                בכלים בודהיסטים, יוגיים, טאוטיסטים ועוד.
            </p>
        </div>
        <img class="my-pic" src={MyPic} alt="my-pic">

        <div class="super-points">
            <h1>תארו לעצמכם...</h1>
            <ul class="checked-list">
                <li>אימון אישי מועשר ומתוגבר בכלים
                    <a href="/lib/the-three-yanas">מהבודהיזם והבודהיזם הטיבטי</a>
                    ומשולב
                    <a href="/lib/healing">בהילינג</a>
                </li>
                <li> כישורי
                    <a href="/lib/education">חינוך, הוראה</a>

                    <a href="/lib/healing"> והילינג</a>
                    מועצמים על ידי
                    <a href="/lib/%D7%90%D7%9E%D7%A0%D7%95%D7%AA%20%D7%94%D7%A7%D7%A9%D7%91">קשב</a>
                    עם בסיס של
                    <a href="/lib/%D7%9E%D7%99%D7%99%D7%A0%D7%93%D7%A4%D7%95%D7%9C%D7%A0%D7%A1">מיידפולנס</a>
                    עמוק ורחב
                    <a href="/lib/healing">ואמצעים מיומנים</a>

                </li>
                <li>תקשורת-מקרבת עם
                    <a href="/lib/%D7%9E%D7%93%D7%99%D7%98%D7%A6%D7%99%D7%94">בסיס מדיטטיבי רב עצמה</a>
                    שמעצים את
                    <a href="/lib/%D7%90%D7%9E%D7%A0%D7%95%D7%AA%20%D7%94%D7%A7%D7%A9%D7%91">הקשב</a>
                    והבהירות
                </li>
                <li>ניהול ומנהיגות המועצמים על ידי הבהירות הצלולה והשלווה של הדהרמה
                    <a href="/lib/%D7%94%D7%90%D7%98%D7%94-%D7%99%D7%95%D7%92%D7%94">והיוגה</a>
                </li>
                <li>גידול
                    <a href="/lib/%D7%9E%D7%95%D7%A8%D7%9B%D7%91%D7%95%D7%AA-%D7%94%D7%A0%D7%A2%D7%95%D7%A8%D7%99%D7%9D">ילדים ונוער</a>
                    כשלרשותכם
                    <a href="/lib/about-the-tools">כלים</a>
                    שישמרו אתכם חיוביים, קשובים, ורגועים יותר
                </li>
                <li>
                    <a href="/lib/%D7%9E%D7%A2%D7%A8%D7%9B%D7%95%D7%AA-%D7%99%D7%97%D7%A1%D7%99%D7%9D">זוגיות</a>
                    על בסיס צמיחה רוחנית משותפת שהיא יותר אינטימית ורוחנית יותר ממה שהעזתם לחלום עליו
                </li>
                <li>התגברות על בעיות רגשיות,
                    <a href="/lib/habits">התמכרויות, הרגלים רעים</a>
                    ולמעשה הפיכתם לכלי לצמיחה והעצמה עם חכמת
                    <a href="/lib/%D7%90%D7%9E%D7%A0%D7%95%D7%AA%20%D7%94%D7%A7%D7%A9%D7%91">הטנטרה</a>

                    ו<a href="/lib/%D7%A4%D7%A1%D7%99%D7%9B%D7%95%D7%9C%D7%95%D7%92%D7%99%D7%AA-%D7%A2%D7%95%D7%9E%D7%A7">פסיכולוגית עומק בודהיסטית</a>

                </li>
                <li>
                    לימוד דהרמה למתחילים ולמתקדמים (על בסיס דאנה) בקבוצות ובאופן אישי
                </li>

            </ul>
        </div>
        <div class="dana-declaration">
            <div>
                שימו לב, לו-ג'ונג איננו למטרת רווח, והפעילות בו היא (כמעט כל-כולה) מבוססת על נדיבות הלב,
                <span>
      <a href="/lib/dana" target="_blank"> (דהאנה)</a>
        </span>
                בהתאם למסורת הבודהיסטית העתיקה.
            </div>
        </div>

        <div class="who-are-we">
            <h2>מי אנחנו</h2>
            <p>
                <a href="/about-me">
                    אבי תשובה
                </a>
                , מייסד לו-ג'ונג, מביא עימו ניסיון רב, של עשרות שנים, בתחומי המדיטציה, היוגה והטאואיזם, כמו גם בעולם
                העסקים
                וההייטק ומציע שיטות מעשיות ומותאמות לצרכים הייחודיים של החיים המודרניים.
                את הפעילות מלווה לעיתים גם
                <a href="https://www.barclinic.co.il/" target="_blank">גדי בר </a>מומחה לרפואה סינית ויפנית, שישלים את
                מה
                שרלוונטי עבור מי שזקוק לכך.
            </p>
            <p>
                אגב, פירוש השם
                <b>לו-ג'ונג</b>
                הוא "אימון-התודעה", והוא מתייחס ללימוד מאוד ספציפי בבודהיזם הטיבטי. ולא - לא נלמד את
                הלימוד המסוים הזה במסגרות הרגילות שאנו מציעים, אך ניקח גם משם כלים ועקרונות שימושיים.
            </p>
        </div>

        <div class="programs">
            <h2>הצטרפו אלינו לסדנאות ותוכניות מיוחדות</h2>
            <ul>
                <li> סדרות מפגשים מתקדמות לפיתוח אישי ומקצועי למאמנים, מתרגלים, אנשי חינוך</li>
                <li>תוכניות מותאמות לארגונים לשיפור ההרמוניה, בריאות העובדים ואיכות העבודה</li>
                <li> ליווי, אימון והדרכה של <a href="/lib/relationships1" target="_blank">זוגות, משפחות</a> קבוצות, <a
                        href="/lib/youth" target="_blank"> ונוער</a></li>
                <li style="color:var(--special-color)">השבחת מורים, מטפלים, מאמנים ומדריכים</li>
            </ul>
        </div>
        <br>
        <plans/>
        <recommendations/>
        <contact-widget/>
        <the-process/>

        <div class="read-more-stuff">
            <h2>
            קראו עוד:
            </h2>
            <ul class="checked-list">
                <li><a href="/articles"> ספרית המאמרים והידע</a></li>
                <li><a href="/lib/about-the-tools"> על הכלים שאנו משתמשים בהם</a></li>
                <li><a href="/for-whom">למי זה מיועד</a></li>
                <li><a href="/about-me">אודותי</a></li>
            </ul>

        </div>
        <contact-widget/>
    </article>

    <contact-form if={state.contactFormOpen} closeCB={()=>openContactForm(false)}/>

    <style>
        the-process article {
            max-height: fit-content;
        }

        .article-carousel {
            display: flex;
            flex-direction: column;
            align-items: center;

        }

        .article-carousel h2 {
            align-self: start;
        }

        recent-articles {
            max-width: 60em;
        }

        .english {
            direction: ltr;
            font-size: 1rem;

            transform: rotate(10deg);
            text-align: center;
        }

        .english a {
            font-weight: bold;
            background-color: var(--opaque-background-color);
            border: 4px double var(--accent-color);
            padding: 3px;
            border-radius: 1em;
        }

    </style>
    <script>

        import ArticleDisplay from './article-display.riot'
        import TheProcess from './the-process.riot'
        import ContactWidget from '../contact-widget.riot'
        import Recommendations from '../recommendations.riot'
        import RecentArticles from '../recent-articles.riot'
        import Plans from './plans.riot'
        import MyPic from '../../styles/resources/avi-a.jpg'

        export default {
            components: {
                ArticleDisplay,
                TheProcess,
                ContactWidget,
                Recommendations,
                RecentArticles,
                Plans
            },
            MyPic
        }
    </script>

</welcome>